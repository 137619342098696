import { useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { DefaultResponseWithData } from "../models/system";

export default function useWidgetToken() {
    const { get } = useApiHelper();

    const widgetToken = useQuery({
        queryKey: ["widgetToken"],
        queryFn: () => get<DefaultResponseWithData<string>>("/widget/token"),
        refetchInterval: 1000 * 60 * 60,
    });

    return {
        widgetToken,
    };
}