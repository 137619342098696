import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

import Modal from "../../layouts/Modal";
import { TextInput } from "../shared/Inputs/TextInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";

import useWilmaUsers from "../../data/useWilmaUsers";
import { IUserInviteProps } from "../../models/auth/WilmaUser";


export default function AdminUserInviteModal({ open, setOpen }: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
    const { saveHandlers } = useDefaultCRUDHandlers("Users");
    const { inviteWilmaUser } = useWilmaUsers();
    
    const { handleSubmit, register, setValue, watch, reset } = useForm<IUserInviteProps>({
        defaultValues: {
            email: "",
            forename: "",
            surname: "",
            password: "",
            username: "",
        }
      });

    const onSubmit = (data: IUserInviteProps) => {
        inviteWilmaUser.mutate({
                ...data,
                username: data.email,
                password: "password"
            }, {
            onSuccess: () => {
                setOpen(false);
                saveHandlers.onSuccess();
            },
            onError: (error) => {
                console.error(error);
                saveHandlers.onError();
            }
        });
    }
    
    return (
        <Modal width="max-w-6xl" open={open} setOpen={setOpen} >
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 pt-4">
                <h1 className="text-xl">Invite a new user</h1>
                <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-2">
                    <TextInput label="First Name" registerName={"forename"} register={register}/>
                    <TextInput label="Last Name" registerName={"surname"} register={register}/>
                </div>
                <TextInput label="Email" registerName={"email"} register={register}/>
                <ButtonInput label="Invite User" isSubmit />
            </form>
        </Modal>
    )
}