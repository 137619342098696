import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { useAuthApi } from "../hooks/useAuthApi";
import useApiHelper from "../hooks/useApiHelper";
import { IUserInviteProps } from "../models/auth/WilmaUser";

export default function useWilmaUsers() {
  const { getUsers } = useAuthApi();
  const { post } = useApiHelper();
  const queryClient = useQueryClient();

  const wilmaUsers = useQuery({
    queryKey: ["wilmaUsers"],
    queryFn: () => getUsers(),
    refetchInterval: 1000 * 10,
  });

  const inviteWilmaUser = useMutation(
    (data: IUserInviteProps) => post(`/customer/users/invite`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["wilmaUsers"]);
      },
    }
  );

  return {
    wilmaUsers,
    inviteWilmaUser,
  };
}
