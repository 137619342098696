import {
  appRegistrationIdStorageKey,
  wilmaSSOAPIUrl,
  wilmaSSOAppId,
  wilmaSSODashboardUrl,
} from "../config";
import { IWilmaUser } from "../models/auth/WilmaUser";
import { DefaultResponse, DefaultResponseWithData } from "../models/system";
import { handleResponse } from "../utils/handleResponse";

export function useAuthApi() {
  const getToken = async (applicationId? : string): Promise<DefaultResponseWithData<{ token: string, applicationRegistrationId: string | null }> | undefined> => {

    try {
      const tokenRes = await fetch(`${wilmaSSOAPIUrl}/v1/auth/token`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          applicationRegistrationId: localStorage.getItem(appRegistrationIdStorageKey),
          applicationId: applicationId,
        }),
      });

      return await handleResponse<DefaultResponseWithData<{ token: string, applicationRegistrationId: string | null }>>(
        tokenRes
      );
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        localStorage.removeItem(appRegistrationIdStorageKey);

        if (applicationId) {
          console.log("Redirecting to SSO 1");
          _redirectToSSO(wilmaSSOAppId);
          // window.location.assign(
          //   `${wilmaSSODashboardUrl}?app=${wilmaSSOAppId}&returnUrl=${window.location.href}`
          // );
        } else return await getToken(wilmaSSOAppId);

      }
    }
  };

  const getUsers = async () => {
    try {
      const usersRes = await fetch(`${wilmaSSOAPIUrl}/v1/customer/users`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await handleResponse<DefaultResponseWithData<IWilmaUser[]>>(
        usersRes
      );
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        localStorage.removeItem(appRegistrationIdStorageKey);
        console.log("Redirecting to SSO 2");
        _redirectToSSO(wilmaSSOAppId);
        // window.location.assign(
        //   `${wilmaSSODashboardUrl}?app=${wilmaSSOAppId}&returnUrl=${window.location.href}`
        // );
      }
    }
  };

  const getUser = async () => {
    const userRes = await fetch(`${wilmaSSOAPIUrl}/v1/user`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await handleResponse<DefaultResponseWithData<IWilmaUser>>(userRes);
  };

  const validatePin = async (pin: string) => {
    const pinRes = await fetch(`${wilmaSSOAPIUrl}/v1/user/pin`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pin: pin,
      }),
    });

    return await handleResponse<DefaultResponse>(pinRes);
  };

  const updatePin = async (pin: string, password: string) => {
    const pinRes = await fetch(`${wilmaSSOAPIUrl}/v1/user/pin`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pin: pin,
        password: password,
      }),
    });

    return await handleResponse<DefaultResponse>(pinRes);
  };

  const logout = async () => {
    try {
      const logoutRes = await fetch(`${wilmaSSOAPIUrl}/v1/auth/logout`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          applicationRegistrationId: localStorage.getItem(appRegistrationIdStorageKey),
        }),
      });

      return await handleResponse<DefaultResponse>(logoutRes);
    } catch (e: any) {
      if (e["response"] && e.response.status === 401) {
        localStorage.removeItem(appRegistrationIdStorageKey);
        console.log("Redirecting to SSO 3");
        _redirectToSSO(wilmaSSOAppId);
        // window.location.assign(
        //   `${wilmaSSODashboardUrl}?ari=${localStorage.getItem(appRegistrationIdStorageKey)}&returnUrl=${window.location.href}`
        // );
      }
    }
  };

  const _redirectToSSO = (appId : string | undefined) => {
    window.location.assign(
      `${wilmaSSODashboardUrl}?app=${appId}&returnUrl=${window.location.href}`
    );
  }

  return {
    getToken,
    getUsers,
    getUser,
    validatePin,
    updatePin,
    logout,
  };
}
