import { useEffect, useState } from "react";

import AdminUserTable from "./AdminUserTable";
import AdminUserInviteModal from "./AdminUserInviteModal";

import { IWilmaUser } from "../../models/auth/WilmaUser";

import useWilmaUsers from "../../data/useWilmaUsers";
import LoadingWheel from "../shared/LoadingWheel";
import { PlusSmallIcon } from "@heroicons/react/20/solid";

export default function AdminUsers() {
    const { wilmaUsers } = useWilmaUsers();
    const [users, setUsers] = useState<IWilmaUser[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [open, setOpen] = useState(false);

    const handleInviteUser = () => {
        setOpen(true);
    }

    useEffect(() => {
        if (wilmaUsers.data && !wilmaUsers.isLoading) {
            setIsLoading(false);
            setUsers(wilmaUsers.data.data);
        }
    }, [wilmaUsers.data, wilmaUsers.isLoading]);

    if(isLoading) return <LoadingWheel />

    return (
        <div>
            <AdminUserInviteModal open={open} setOpen={setOpen} />
            <h1 className="mb-2">Admin Users</h1>
            <button
                className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
                type="button"
                onClick={() => handleInviteUser()}
            >
                <PlusSmallIcon className="h-5 w-5 mr-2" />
                Add New User
            </button>
            <AdminUserTable users={users} />
        </div>
    )
}