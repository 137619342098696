import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IManufacturerPriceSetting, IManufacturerPriceSettingResponse } from '../models/PriceSettings';

export default function useManufacturerPriceSettingSingle({ manufacturerPriceSettingId }: { manufacturerPriceSettingId?: string }) {
    const { get, put } = useApiHelper();
    const queryClient = useQueryClient();

    const baseManufacturerPriceSetting = useQuery({
        queryKey: ["manufacturerPriceSettings"],
        queryFn: () => get<IManufacturerPriceSettingResponse>("/PriceSetting/manufacturer/base"),
        refetchInterval: 1000 * 10,
        // enabled: !manufacturerPriceSettingId,
    });

    const manufacturerPriceSetting = useQuery({
        queryKey: ["manufacturerPriceSettings", manufacturerPriceSettingId],
        queryFn: () => get<IManufacturerPriceSettingResponse>(`/PriceSetting/manufacturer/${manufacturerPriceSettingId}`),
        refetchInterval: 1000 * 10,
        enabled: !!manufacturerPriceSettingId,
    });

    const update = useMutation(
        (priceSetting: IManufacturerPriceSetting) => put(`/PriceSetting/manufacturer/${priceSetting.id}`, priceSetting),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["manufacturerPriceSettings"]);
            },
        }
    );

    return {
        baseManufacturerPriceSetting,
        manufacturerPriceSetting,
        update,
    };
}