import { ICustomer } from "./ICustomer";
import { BaseEntity, DefaultResponseWithData } from "./system";


export interface IAllPriceSettingsResponse extends DefaultResponseWithData<AllPriceSettings> { };
export interface IManufacturerPriceSettingResponse extends DefaultResponseWithData<IManufacturerPriceSetting> { };
export interface IManufacturerPriceSettingsResponse extends DefaultResponseWithData<IManufacturerPriceSetting[]> { };

export interface AllPriceSettings {
  customerPriceConfiguration: ICustomerPriceConfiguration | null;
  genericPriceSettings: ICustomerGenericPriceSetting[];
  salvagePriceSettings: ICustomerSalvagePriceSetting[];
}


export interface ICustomerPriceConfiguration extends BaseEntity {
  doesCollection: boolean;
  doesDropOff: boolean;

  customerId: string;
  customer: ICustomer | null;

  collectionPriceSettingId: string | null;
  collectionPriceSetting: IPriceSetting | null;

  dropOffPriceSettingId: string | null;
  dropOffPriceSetting: IPriceSetting | null;

  alloyWheelsPriceSettingId: string | null;
  alloyWheelsPriceSetting: IPriceSetting | null;

  noFaultPriceSettingId: string | null;
  noFaultPriceSetting: IPriceSetting | null;

  // catalyticConverterPriceSettingId: string | null;
  // catalyticConverterPriceSetting: IPriceSetting | null;

  // exportPriceSettingId: string | null;
  // exportPriceSetting: IPriceSetting | null;
  customerPriceRules: ICustomerPriceRule[];
}

export interface ICustomerPriceRule extends BaseEntity {
  value: number;
  type: CustomerPriceRuleType;
  condition: PriceCondition;

  customerPriceConfigurationId: string;
  customerPriceConfiguration: ICustomerPriceConfiguration | null;

  priceSettingId: string;
  priceSetting: IPriceSetting | null;
}


export interface ICustomerGenericPriceSetting extends BaseEntity {
  make: string | null;
  model: string | null;
  fromYear: number | null;
  toYear: number | null;
  fuelType: string | null;
  engineCode: string | null;
  fromEngineCc: number | null;
  toEngineCc: number | null;

  customerPriceConfigurationId: string;
  customerPriceConfiguration: ICustomerPriceConfiguration | null;
  
  priceSettingId: string;
  priceSetting: IPriceSetting | null;
}


export interface ICustomerSalvagePriceSetting extends BaseEntity {
  engineCondition: EngineCondition;
  bodyCondition: BodyCondition;
  customerPriceConfigurationId: string;
  customerPriceConfiguration: ICustomerPriceConfiguration | null;
  priceSettingId: string;
  priceSetting: IPriceSetting | null;
}

export interface IManufacturerPriceSetting extends BaseEntity {
  value: number;
  make: string | null;

  customerPriceConfigurationId: string;
  customerPriceConfiguration: ICustomerPriceConfiguration | null;

  priceSettingId: string;
  priceSetting: IPriceSetting | null;
}

export interface IPriceSetting extends BaseEntity {
  isFixed: boolean;
  value: number;
}

export enum EngineCondition {
  NoFault,
  MinorFault,
  MajorFault
}

export enum BodyCondition {
  Good,
  Average,
  HeavyDamage
}

export enum CustomerPriceRuleType {
  CatalyticConverter,
  Export,
  VfeExport
}

export enum PriceCondition {
  LessThan,
  LessThanOrEqual,
  Equal,
  GreaterThanOrEqual,
  GreaterThan
}