import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useWidgetToken from "../../data/useWidgetToken";
import LoadingWheel from "../../components/shared/LoadingWheel";
import useNotification from "../../components/notifications/useNotifications";
import useWilmaUser from "../../data/useWilmaUser";

export default function AdminEmbed() {
    const { setInfo } = usePageNameContext();
    const { widgetToken } = useWidgetToken();
    const { wilmaUser: user } = useWilmaUser();
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState<string>("");
    const [customerId, setCustomerId] = useState<string>("");
    const { addNotification } = useNotification();

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        addNotification({
            variant: "success",
            primaryText: "Copied to clipboard",
        });
    }

    useEffect(() => {
        setInfo({
            name: "Admin Embed",
            desc: "View embed code for the Wilma Widget",
        });
    }, []);

    useEffect(() => {
        if (!widgetToken.isLoading && widgetToken.data && !user.isLoading && user.data) {
            setIsLoading(false);
            setToken(widgetToken.data.data);
            // cast use to WilmaUser
            console.log("User", user.data.data);
            setCustomerId(user.data.data.customerId);      
        }
    }, [widgetToken.data, widgetToken.isLoading, user.data, user.isLoading]);

    if (isLoading) return <LoadingWheel />

    return (
        <div>
            <div className="flex flex-col items-center max-w-full px-4">
                <h1 className="text-2xl font-bold">Embed Code</h1>
                <p className="text-gray-600 mb-4">Click to copy embed code</p>
                
                <div className="w-full break-words text-cyan-700 p-4 border-l-4 bg-cyan-50 border-cyan-500 hover:cursor-pointer"
                    onClick={() => copyToClipboard(getEmbedCode(token, customerId))}>
                    {getEmbedCode(token, customerId)}
                </div>
            </div>
        </div>
    )
}

function getEmbedCode(token: string, customerId: string) {
    let embedCode = `<div id="my-widget-container">
            <script>
            (function(w, d, s, customerId, token) {
                var js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                js.src = "https://widget.recyclingsoftware.co.uk/widget.js";
                js.onload = function() {
                if (w.MyWidget) {
                    w.MyWidget.init({
                    customerId: customerId,
                    token: token
                    });
                }
                };
                fjs.parentNode.insertBefore(js, fjs);     
            })(window, document, 'script', 'CUSTOMER_ID', 'CUSTOMER_TOKEN');
            </script>
        </div>
    `;

    embedCode = embedCode.replace("CUSTOMER_ID", customerId);
    embedCode = embedCode.replace("CUSTOMER_TOKEN", token);

    // minify
    embedCode = embedCode.replace(/[\n\t]/g, "");

    return embedCode;
}