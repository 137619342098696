import { usePageNameContext } from "../../contexts/PageTitleContext";
import { Link, useParams } from "react-router-dom";
import classNames from "../../utils/classNames";
import AuthConnections from "./AuthConnections";
import { useEffect } from "react";

import AdminCredentials from './../AdminCredentials/AdminCredentials';

import {
  ClipboardDocumentListIcon as ClipboardDocumentListOutlineIcon,
  MapPinIcon as MapPinOutlineIcon,
  TagIcon as TagOutlineIcon,
  TruckIcon as TruckOutlineIcon,
  UserIcon as UserOutlineIcon,
  CurrencyPoundIcon as CurrencyPoundOutlineIcon,
  LinkIcon as LinkOutlineIcon,
  Square3Stack3DIcon as Square3Stack3DOutlineIcon,
  CubeIcon as CubeOutlineIcon,
  BuildingOfficeIcon as BuildingOfficeOutlineIcon,
  DocumentTextIcon as DocumentTextOutlineIcon,
  CommandLineIcon as CommandLineOutlineIcon,
  UserGroupIcon as UserGroupOutlineIcon,
} from "@heroicons/react/24/outline"

import {
  ClipboardDocumentListIcon as ClipboardDocumentListSolidIcon,
  MapPinIcon as MapPinSolidIcon,
  TagIcon as TagSolidIcon,
  TruckIcon as TruckSolidIcon,
  UserIcon as UserSolidIcon,
  CurrencyPoundIcon as CurrencyPoundSolidIcon,
  LinkIcon as LinkSolidIcon,
  Square3Stack3DIcon as Square3Stack3DSolidIcon,
  CubeIcon as CubeSolidIcon,
  BuildingOfficeIcon as BuildingOfficeSolidIcon,
  DocumentTextIcon as DocumentTextSolidIcon,
  CommandLineIcon as CommandLineSolidIcon,
  UserGroupIcon as UserGroupSolidIcon,
} from "@heroicons/react/24/solid"
import AdminEmbed from "./AdminEmbed";
import AdminUsers from "../../components/adminUsers/AdminUsers";




export default function AdminMenu() {
  const { setInfo } = usePageNameContext();
  const { section } = useParams();

  // Method for returning the correct admin setting page
  const getPanel = () => {
    switch (section) {
      case "embed":
        return <AdminEmbed />
      case "users":
        return <AdminUsers />
      default:
        return <AdminCredentials />
    }
  }

  // This is the sub-menu inside of the admin menu, ordered as such
  const subNavigation = () => {
    return [
      { icon: ClipboardDocumentListOutlineIcon, filledIcon: ClipboardDocumentListSolidIcon, name: "Admin Credentials", href: "" },
      // { icon: LinkOutlineIcon, filledIcon: LinkSolidIcon, name: "Auth Connections", href: "connections" },
      // { icon: MapPinOutlineIcon, filledIcon: MapPinSolidIcon, name: "Locations", href: "locations" },
      // { icon: CurrencyPoundOutlineIcon, filledIcon: CurrencyPoundSolidIcon, name: "Scrap Price", href: "scrap-price" },
      { icon: CommandLineOutlineIcon, filledIcon: CommandLineSolidIcon, name: "Embed Widget", href: "embed" },
      { icon: UserGroupOutlineIcon, filledIcon: UserGroupSolidIcon, name: "Users", href: "users" },
    ]
  }

  useEffect(() => {
    setInfo({
      name: "Admin Settings",
      desc: "Manage admin-level settings and options",
    });
  }, []);

  // Assuming that no loading indicator wheel is needed here

  return (
    <>
      <div className="mt-4 bg-white rounded-lg shadow">
        <div className="min-h-[256px] divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
          <aside className="py-6 lg:col-span-2">
            <nav className="space-y-1">
              {subNavigation().map((item) => {
                let currentSection = section ? section : "";
                let current = item.href === currentSection;
                return (
                  <Link
                    key={item.name}
                    to={`${item.href !== "" ? `${item.href}` : ""}`}
                    className={classNames(
                      current
                        ? "bg-cyan-50 border-cyan-500 text-cyan-700 hover:bg-cyan-50 hover:text-cyan-700"
                        : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                      "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                    )}
                    aria-current={current ? "page" : undefined}
                  >
                    {current ? (
                      <item.filledIcon
                        className={classNames(
                          "text-cyan-700 group-hover:text-cyan-700",
                          "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                    ) : (
                      <item.icon
                        className={classNames(
                          "text-gray-400 group-hover:text-gray-500",
                          "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                    )}
                    <span className="truncate">{item.name}</span>
                  </Link>
                );
              })}
            </nav>
          </aside>
          <div className="bg-gray-50 rounded-r-lg lg:col-span-10">
            <div className="h-full py-6 px-4 sm:p-6 lg:pb-8">
              {getPanel()}
            </div>
          </div>
        </div>
      </div>
    </>
  )

}