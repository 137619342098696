import { BaseEntity } from "../system";

export interface IWilmaUser extends BaseEntity {
  status: WilmaUserStatus,
  forename: string;
  surname: string;
  username: string;
  email: string;
  hasPin: boolean;
  customerId: string;
  lastLogin: string; 
}

export enum WilmaUserStatus {
  Archived,
  Disabled,
  Enabled,
  Invited
}

export interface IUserInviteProps {
  forename: string;
  surname: string;
  email: string;
  password: string;
  username: string;
}
