import { useForm } from "react-hook-form";
import {
    IManufacturerPriceSetting
} from "../../models/PriceSettings";
import { TextInput } from "../shared/Inputs/TextInput";
import SelectInput from "../shared/Inputs/SelectInput";
import GeneralPriceSettingSingle from "./PriceConfiguration/GeneralPriceSettingSingle";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useManufacturerPriceSettingSingle from "../../data/useManufacturerPriceSettingSingle";
import { useEffect, useState } from "react";

interface IManufacturerPriceSettingParams {
    customerPriceConfigurationId: string;
    priceSetting?: IManufacturerPriceSetting;
    onSuccess?: any;
}

export default function ManufacturerPriceSingle({
    customerPriceConfigurationId,
    priceSetting,
    onSuccess,
}: IManufacturerPriceSettingParams) {
    const { manufacturerPriceSetting, update } = useManufacturerPriceSettingSingle({ manufacturerPriceSettingId: priceSetting?.id });
    const { saveHandlers } = useDefaultCRUDHandlers("Manufacturer Price Setting");
    const [isLoading, setIsLoading] = useState(true);
    const { handleSubmit, register, setValue, watch, reset } = useForm<IManufacturerPriceSetting>({
        defaultValues: {
            id: priceSetting?.id || "",
            customerPriceConfigurationId: customerPriceConfigurationId,
            value: priceSetting?.value || 0,
            make: priceSetting?.make || "",

            priceSetting: {
                value: priceSetting?.priceSetting?.value || 0,
                isFixed: priceSetting?.priceSetting?.isFixed || true,
            },
        },
    });

    const onSubmit = (data: any) => {
        if (priceSetting){
            update.mutate(data, {
                ...saveHandlers,
                onSuccess: () => {
                    saveHandlers.onSuccess();
                    onSuccess();
                }
            });
        }else{
            alert("Not yet implemented");
        }
    }

    const onRemove = () => {
        if (priceSetting){
            alert("Not yet implemented");
        }
    }

    useEffect(() => {
        console.log(priceSetting);
        console.log(isLoading);
        if (priceSetting && isLoading) {
            reset(priceSetting);

            setIsLoading(false);
        } else if (!priceSetting && isLoading) {
            // setIsLoading(false);
        }
    }, [priceSetting]);
    
    if (isLoading) return null;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
            <div className="flex flex-col lg:flex-row items-center gap-x-4">      
                {priceSetting && priceSetting.make == "Base" ? <></> :
                    <TextInput 
                        label="Make" 
                        register={register} 
                        registerName="make" 
                        placeholder="Audi, BMW, Ford etc."
                        cols="w-64" 
                    />
                }  
                <TextInput
                    label={"Threshhold"}
                    registerName={`value`}
                    register={register}
                    placeholder="Make, Model, Year etc. "
                    cols="w-64"
                />

                <span className="pt-8 mr-[-10px]">-</span>
                <GeneralPriceSettingSingle
                    label={"Price Deduction"}
                    objectName={"priceSetting"}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    minVal={null}
                />

                <div className="flex grow mt-[28px] space-x-4">
                    <ButtonInput label={priceSetting ? "Save" : "Create"} isSubmit />
                    {priceSetting && priceSetting.make != "Base" && (
                        <ButtonInput
                        label="Delete"
                        isSubmit={false}
                        onClick={onRemove}
                        classes="bg-red-600 hover:bg-red-600/80"
                        />
                    )}
                </div>
            </div>
        </form>
    )

}