import { useQuery } from "@tanstack/react-query";

import { useAuthApi } from "../hooks/useAuthApi";

export default function useWilmaUser() {
  const { getUser } = useAuthApi();

  const wilmaUser = useQuery({
    queryKey: ["wilmaUser"],
    queryFn: () => getUser(),
    refetchInterval: 1000 * 10,
  });

  return {
    wilmaUser,
  };
}
