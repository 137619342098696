import FormRadioGroupIcons from "../../shared/Inputs/FormRadioGroupIcons";
import { NumberInput } from "../../shared/Inputs/NumberInput";

interface IGeneralPriceSettingsProps {
  label: string;
  objectName: string;
  register: any;
  setValue: any;
  watch: any;
  minVal?: number | null;
  disabled?: boolean;
}

interface IOptions {
  value: Boolean;
  name: string;
}

export default function GeneralPriceSettingSingle({ label, objectName, register, setValue, watch, minVal, disabled }: IGeneralPriceSettingsProps) {

  const options: IOptions[] = [
    { value: true, name: "£" },
    { value: false, name: "%" }
  ]

  const valueRegisterName = objectName ? `${objectName}.value` : "value";
  const isFixedRegisterName = objectName ? `${objectName}.isFixed` : "isFixed";

  return (
    <div className={`flex items-center gap-x-4 ${disabled ? "opacity-50 pointer-events-none" : ""}`}>
      <NumberInput label={label} registerName={valueRegisterName} register={register} classes="w-32 lg:w-64" minVal={minVal} />
      <FormRadioGroupIcons label="" register={register} registerName={isFixedRegisterName} options={options} setValue={setValue} watch={watch} containerClassName="mt-7" />

      {/* <SelectInput label="" register={register} registerName={isFixedRegisterName} options={options} setValue={setValue} watch={watch} cols="pt-5 w-64" /> */}
    </div>
  )
}