import { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import ExportLeads from "../../components/leads/LeadTable/ImportExport/ExportLeads";
import LeadsTable from "../../components/leads/LeadTable/LeadsTable";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useLeads from "../../data/useLeads";
import Modal from "../../layouts/Modal";
import { ILead, LeadSource, LeadStatus } from "../../models/Lead";

export default function Leads() {
  const { setInfo } = usePageNameContext();
  // const { unreadMessages } = useMessages();
  const { leads: allLeads } = useLeads();
  const [searchParams] = useSearchParams();
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [exportOpen, setExportOpen] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const [showUnreadQueryString, setShowUnreadQueryString] = useState<boolean>(
    searchParams.get("unread") === "1" ? true : false
  );
  const [showRescheduleQueryString, setRescheduleQueryString] =
    useState<boolean>(searchParams.get("reschedule") === "1" ? true : false);
  // const [usedQuery, setUsedQuery] = useState<boolean>(searchParams.get("unread") === "1" ? false : true);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentStatusFilter, setCurrentStatusFilter] =
    useState("Awaiting Contact");
  const [currentStatusFilterIdx, setCurrentStatusFilterIdx] = useState(0);
  const [currentWebsiteFilter, setCurrentWebsiteFilter] =
    useState("All Sources");
  const [currentWebsiteFilterIdx, setCurrentWebsiteFilterIdx] = useState(5);
  const leadsTableRef = useRef();

  // const refreshTriggers = useDebounce(
  //   [searchTerm, currentStatusFilterIdx, currentWebsiteFilterIdx],
  //   500
  // );

  // const { leadsWithFilters } = useLeadsWithFilters({
  //   query: refreshTriggers[0] as string,
  //   status: refreshTriggers[1] as number,
  //   website: refreshTriggers[2] as number,
  // });

  const statusFilters = [
    ...Object.values(LeadStatus).filter((ct) => isNaN(Number(ct))),
    "All",
  ];

  const websiteFilters = [
    ...Object.values(LeadSource).filter((ct) => isNaN(Number(ct))),
    "All Sources",
  ];

  useEffect(() => {
    setInfo({
      name: "Leads",
      desc: "View and manage your leads here",
    });
  }, []);

  useEffect(() => {
    if (!allLeads.isLoading) { // && !unreadMessages.isLoading) {
      let filter = allLeads.data?.data;

      console.log("lead", allLeads.data);

      // if (showUnread || showUnreadQueryString) {
      //   if (showUnreadQueryString && !showUnread) {
      //     setShowUnread(true);
      //   }
      //   setCurrentStatusFilter("All");
      //   setCurrentStatusFilterIdx(9);
      //   filter = filter?.filter(
      //     (lead) =>
      //       unreadMessages.data?.data.find((m) => m.leadId === lead.id)
      //         ?.unreadMessageCount! > 0
      //   );
      //   setShowUnreadQueryString(false);
      // } else if (showRescheduleQueryString) {
      //   setCurrentStatusFilter("Reschedule");
      //   setCurrentStatusFilterIdx(LeadStatus.Reschedule);
      //   setRescheduleQueryString(false);
      // }

      // //find any lead with an unread message or premium and move to the top
      // const topLeads = filter?.filter(
      //   (lead) =>
      //     unreadMessages.data?.data.find((m) => m.leadId === lead.id)
      //       ?.unreadMessageCount! > 0 || lead.vehicle?.vehiclePremium
      // );
      // const restOfLeads = filter?.filter(
      //   (lead) =>
      //     unreadMessages.data?.data.find((m) => m.leadId === lead.id)
      //       ?.unreadMessageCount! === 0 && !lead.vehicle?.vehiclePremium
      // );

      // filter = topLeads?.concat(restOfLeads!);


      filter = filter?.filter((lead) => {
        if (currentStatusFilter === "All") {
          return true;
        } else {
          return lead.status === LeadStatus[currentStatusFilter as keyof typeof LeadStatus];
        }
      });

      filter = filter?.filter((lead) => {
        if (currentWebsiteFilter === "All Sources") {
          return true;
        } else {
          return lead.website === LeadSource[currentWebsiteFilter as keyof typeof LeadSource];
        }
      });

      filter = filter?.filter((lead) => {
        if (searchTerm === "") {
          return true;
        } else {
          return (
            lead.contact?.fullname?.toLowerCase().includes(searchTerm) ||
            lead.vehicle?.vrm?.toLowerCase().includes(searchTerm) ||
            lead.contact?.postcode?.toLowerCase().includes(searchTerm)
          );
        }
      });

      // sort by date
      filter = filter?.sort((a, b) => {
        if (a.createdDate < b.createdDate) {
          return 1;
        } else if (a.createdDate > b.createdDate) {
          return -1;
        } else {
          return 0;
        }
      });

      console.log("filter", filter);

      setLeads(filter);
      setIsLoading(false);
    }
  }, [
    allLeads.isLoading,
    allLeads.data,
    searchTerm,
    currentWebsiteFilter,
    currentStatusFilter,
    // unreadMessages.isLoading,
    // unreadMessages.data,
    // refreshTriggers,
    showUnread,
  ]);

  return (
    <>
      <Modal open={exportOpen} setOpen={setExportOpen} width="sm:max-w-3xl">
        <ExportLeads
          onCancel={() => setExportOpen(false)}
          onExport={() => console.log()}
        />
      </Modal>

      <div className="mt-2 flex justify-between">
        <div className="flex gap-x-5">
          <select
            aria-label="Lead status filter"
            onChange={(e) => {
              setCurrentStatusFilter(e.target.value);
              setCurrentStatusFilterIdx(e.target.selectedIndex);
            }}
            value={currentStatusFilter}
            className="sm:text-sm"
          >
            {statusFilters.map((filter) => (
              <option className="sm:text-sm" key={filter}>
                {String(filter)}
              </option>
            ))}
          </select>

          <select
            aria-label="Lead website filter"
            onChange={(e) => {
              setCurrentWebsiteFilter(e.target.value);
              setCurrentWebsiteFilterIdx(e.target.selectedIndex);
            }}
            value={currentWebsiteFilter}
            className="sm:text-sm"
          >
            {websiteFilters.map((filter) => (
              <option className="sm:text-sm" key={filter}>
                {String(filter)}
              </option>
            ))}
          </select>

          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 sm:text-sm"
              placeholder="Name, VRM or Postcode"
            />
          </div>

          {/* <div className="flex items-center">
            <input
              id="showUnread"
              onChange={(e) => setShowUnread(e.target.checked)}
              value="showUnread"
              checked={showUnread}
              type="checkbox"
              className="mr-2 h-5 w-5"
            />
            <label htmlFor="showUnread">Show Unread</label>
          </div> */}
        </div>
        <div className="flex items-center">
          {/* <Button onClick={() => setExportOpen(true)}>
            <div className="flex items-center">
              <DocumentArrowDownIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Export to CSV
            </div>
          </Button> */}
        </div>
      </div>
      {allLeads.isLoading || isLoading ? (
        <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
          <LoadingWheel />
        </div>
      ) : (
        <LeadsTable data={leads!} />
      )}
    </>
  );
}
