import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { jwtDecode } from "jwt-decode";

import { useAuthApi } from "../hooks/useAuthApi";
import { TokenPayload } from "../models/auth/TokenPayload";
import {
  appRegistrationIdStorageKey,
  tokenStorageKey,
  wilmaSSOAppId,
  wilmaSSODashboardUrl,
} from "../config";
import { IWilmaUser } from "../models/auth/WilmaUser";

interface IEmptyAuthContext {
  token?: string;
  userId?: string;
  customerId?: string;
  name?: string;
  forename?: string;
  surname?: string;
  email?: string;
  username?: string;
  hasPin?: boolean;
  claims?: {
    isAdmin: boolean;
    isDriver: boolean;
    isMechanic: boolean;
    isStaff: boolean;
  };
  loggedIn: false;
  loading: boolean;
}

interface IPopulatedAuthContext {
  token: string;
  userId: string;
  customerId: string;
  name: string;
  forename: string;
  surname: string;
  email: string;
  username: string;
  hasPin: boolean;
  claims: {
    isAdmin: boolean;
    isDriver: boolean;
    isMechanic: boolean;
    isStaff: boolean;
  };
  loggedIn: true;
  loading: boolean;
}

type IAuthContext = IEmptyAuthContext | IPopulatedAuthContext;

const initialState: IAuthContext = {
  token: undefined,
  userId: undefined,
  customerId: undefined,
  name: undefined,
  forename: undefined,
  surname: undefined,
  email: undefined,
  username: undefined,
  hasPin: undefined,
  claims: undefined,
  loggedIn: false,
  loading: true,
};

interface AuthContextValue {
  auth: IAuthContext;
  setAuth: Dispatch<AuthContextReducerAction>;
}

export const AuthContext = createContext<AuthContextValue>({
  auth: initialState,
  setAuth: () => {},
});

interface AuthContextProviderProps {
  children: ReactElement | ReactElement[];
}

type AuthContextReducerAction =
  | {
      type: "loading";
      payload: boolean;
    }
  | {
      type: "setUser";
      payload: { token: string; user: IWilmaUser };
    }
  | {
      type: "setToken";
      payload: { token: string };
    }
  | {
      type: "logout";
    };

function authContextReducer(
  state: IAuthContext,
  action: AuthContextReducerAction
): IAuthContext {
  switch (action.type) {
    case "loading":
      let loadingState = {
        ...state,
        loading: action.payload,
      };

      return loadingState;
    case "setUser":
      const { token, user } = action.payload;
      const decodedToken = jwtDecode<TokenPayload>(token);

      let authState = {
        token: action.payload.token,
        userId: decodedToken.userId,
        customerId: decodedToken.customerId,
        name: decodedToken.name,
        forename: user.forename,
        surname: user.surname,
        email: decodedToken.email,
        username: user.username,
        hasPin: user.hasPin,
        claims: {
          isAdmin: decodedToken.isAdmin === "true",
          isDriver: decodedToken.isDriver === "true",
          isMechanic: decodedToken.isMechanic === "true",
          isStaff: decodedToken.isStaff === "true",
        },
        loggedIn: true,
      };

      return {
        ...state,
        ...authState,
      };
    case "setToken":
      return { ...state, token: action.payload.token };
    case "logout":
      localStorage.removeItem(tokenStorageKey);

      return initialState;
    default:
      return state;
  }
}

export function AuthProvider({ children }: AuthContextProviderProps) {
  const { getToken, getUser } = useAuthApi();
  const [auth, setAuth] = useReducer(authContextReducer, initialState);
  let refreshTimeout: NodeJS.Timeout;
  let refreshInterval: NodeJS.Timeout;

  async function getAuthToken(applicationId? : string) {
    const tokenResult = await getToken(applicationId);
    console.log("Token result", tokenResult);
    console.log("Application id", applicationId);
    if (tokenResult && tokenResult.success) {
      if(tokenResult.data.applicationRegistrationId) {
        localStorage.setItem(appRegistrationIdStorageKey, tokenResult.data.applicationRegistrationId);
      }

      // if the application id is set, then the token needs to be set   
      if(applicationId) {
        setAuth({
          type: "setToken",
          payload: { token: tokenResult.data.token },
        });
        const userResult = await getUser();
        if (userResult && userResult.success) {
          setAuth({ type: "loading", payload: false });
          setAuth({
            type: "setUser",
            payload: { token: tokenResult.data.token, user: userResult.data },
          });
        }
      }
    }
    return tokenResult;
  }

  const refreshToken = async () => {
    console.log("Refreshing token");
    try {
      const tokenResult = await getAuthToken();

      if (tokenResult && tokenResult.success) {
        console.log("Token refreshed");
        setAuth({
          type: "setToken",
          payload: { token: tokenResult.data.token },
        });
      } else {
        setAuth({ type: "logout" });
      }
    } catch (e) {
      setAuth({ type: "logout" });
    }
  };

  const sessionRestore = async () => {
    try {
      const tokenResult = await getAuthToken();

      if (tokenResult && tokenResult.success) {
        const userResult = await getUser();

        if (userResult && userResult.success) {
          setAuth({
            type: "setUser",
            payload: { token: tokenResult.data.token, user: userResult.data },
          });

          // refreshTimeout = setTimeout(() => refreshToken(), 1000 * 60 * 14);
          refreshInterval = setInterval(() => refreshToken(), 1000 * 60 * 14);
        }
      } else {
        setAuth({ type: "logout" });
      }
    } catch (e) {
      setAuth({ type: "logout" });
    } finally {
      setAuth({ type: "loading", payload: false });
    }
  };

  const getAri = () => {
    const url = new URL(window.location.href);
    if (url.searchParams.has("ari")) {
      return url.searchParams.get("ari");
    } else {
      return null;
    }
  };

  useEffect(() => {
    setAuth({ type: "loading", payload: true });

    const ariInStorage = localStorage.getItem(appRegistrationIdStorageKey);
    const ariInUrl = getAri();

    if (ariInStorage) {
      sessionRestore();
    } else if (ariInUrl) {
      localStorage.setItem(appRegistrationIdStorageKey, ariInUrl);
      sessionRestore();
    } else {
      localStorage.removeItem(appRegistrationIdStorageKey);
      getAuthToken(wilmaSSOAppId);
    }

    return () => {
      // clearTimeout(refreshTimeout);
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const authCtx = useContext(AuthContext);

  if (!authCtx) {
    throw Error("useAuth() must be used within a AuthProvider");
  }

  return {
    ...authCtx.auth,
    dispatchAuthEvent: authCtx.setAuth,
  };
}
