import { useEffect, useRef, useState, Fragment} from "react";
import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getSortedRowModel,
    useReactTable,
    createColumnHelper,
    SortingState,
  } from "@tanstack/react-table";

import { IWilmaUser, WilmaUserStatus } from "../../models/auth/WilmaUser";
import formatDateTime from "../../utils/formatDateTime";

const colHelper = createColumnHelper<IWilmaUser>();

const cols = [
    colHelper.accessor("forename", {
        header: "Forename",
    }),
    colHelper.accessor("surname", {
        header: "Surname",
    }),
    colHelper.accessor("email", {
        header: "Email",
    }),
    colHelper.display({
        id: "status",
        header: "Status",
        sortingFn: "auto",
        cell: (info) => (
          <span className="flex" >
            <span className="ml-4 m-auto">
                {WilmaUserStatus[info.row.original.status]}
            </span>
          </span>
        )
    }),
    colHelper.display({
        id: "lastlogin",
        header: "Last Login",
        sortingFn: "auto",
        cell: (info) => (
          <span className="flex" >
            <span className="ml-4 m-auto">
                {formatDateTime(info.row.original.lastLogin)}
            </span>
          </span>
        )
    }),
]

export default function AdminUserTable({users = []}: {users: IWilmaUser[]}) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const table = useReactTable({
        data: users,
        columns: cols,
        state: {
        sorting: sorting,
        },
        enableRowSelection: true,
        getExpandedRowModel: getExpandedRowModel(),
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <div className="mt-4 bg-white overflow-auto">
                <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
        
                {/* Table headings - very similar to all the other tables */}
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
                        {headerGroup.headers.map((header, i) => (
                        <th
                            key={header.id}
                            scope="col"
                            className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                        >
                            {header.isPlaceholder
                            ? null
                            : (
                                <div
                                {...{
                                    className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                }}
                                >
                                {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                                {{
                                    asc: ' ▲',
                                    desc: ' ▼',
                                }[header.column.getIsSorted() as string] ?? null}
                                </div>
        
                            )}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                
                {/* Body */}
                <tbody>
                {table.getRowModel().rows.map((row, i) => {
                    let rowCells = row.getVisibleCells();
                    return (
                        <Fragment key={i}>
                        <tr
                            key={row.id}
                            onClick={() => {}}
                            className={"hover:bg-gray-100 hover:cursor-pointer"}
                        >
                            {rowCells.map((cell) => (
                            <td key={cell.id} className="px-3 py-3 border text-sm">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                            ))}
                        </tr>
                        {row.getIsExpanded() && (
                            <></>
                            // <tr>
                            //   {/* 2nd row is a custom 1 cell row */}
                            //   <td colSpan={row.getVisibleCells().length}>
                            //     <LeadsTableSubComponent lead={row.original} />
                            //   </td>
                            // </tr>
                        )}
                        </Fragment>
                    );
                    })}
                </tbody>
        
                </table>
            </div>
        </>
    )
}